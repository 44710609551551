import axios from 'axios';
import { StatusCode } from 'status-code-enum';

import { PhotoRequest } from '@homesusa/photo-request';

const apiUrl = process.env.REACT_APP_API_URL + 'api/photorequests';

const tripCharge = async ({
  photoRequest,
  message,
  method
}: {
  photoRequest: PhotoRequest;
  message?: string;
  method?: string;
}): Promise<boolean> => {
  const uri = `${apiUrl}/${photoRequest.id}/tripcharge` + (method ? `/${method}` : '');
  const data = message ? { message } : {};
  const response = await axios.put(uri, data);
  return response.status == StatusCode.SuccessOK;
};

const approveTripCharge = async (photoRequest: PhotoRequest): Promise<boolean> =>
  tripCharge({ photoRequest, method: 'approve' });

const requestTripCharge = async (photoRequest: PhotoRequest, message: string): Promise<boolean> =>
  tripCharge({ photoRequest, message });

const cancelTripCharge = async (photoRequest: PhotoRequest, message: string): Promise<boolean> =>
  tripCharge({ photoRequest, message, method: 'cancel' });

const rejectTripCharge = async (photoRequest: PhotoRequest, message: string): Promise<boolean> =>
  tripCharge({ photoRequest, message, method: 'reject' });

export { requestTripCharge, approveTripCharge, cancelTripCharge, rejectTripCharge };
