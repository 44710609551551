import axios from 'axios';

import { CompanyHeader } from '@homesusa/auth';
import { PhotoRequestType } from '@homesusa/photo-request';
import { Property, PropertyResponse } from '../interfaces';
import {
  ProfilePropertyQueryFilter,
  ResidentialPropertyQueryFilter
} from 'core/interfaces/query-filters';

const apiUrl = process.env.REACT_APP_API_URL + 'api/quicklister';

const getListings = async (
  companyId?: string,
  params?: ResidentialPropertyQueryFilter
): Promise<PropertyResponse[]> => {
  const response = await axios.get<PropertyResponse[]>(`${apiUrl}/listings`, {
    headers: companyId ? { [CompanyHeader]: companyId } : undefined,
    params
  });
  return response.data;
};

const getCommunities = async (
  companyId?: string,
  params?: ProfilePropertyQueryFilter
): Promise<PropertyResponse[]> => {
  const response = await axios.get<PropertyResponse[]>(`${apiUrl}/communities`, {
    headers: companyId ? { [CompanyHeader]: companyId } : undefined,
    params
  });
  return response.data;
};

const getPlans = async (
  companyId?: string,
  params?: ProfilePropertyQueryFilter
): Promise<PropertyResponse[]> => {
  const response = await axios.get<PropertyResponse[]>(`${apiUrl}/plans`, {
    headers: companyId ? { [CompanyHeader]: companyId } : undefined,
    params
  });
  return response.data;
};

const getListingById = async (listingId: string): Promise<Property> => {
  return await axios
    .get<Property>(`${apiUrl}/listings/${listingId}`)
    .then((response) => response.data);
};

const getCommunityById = async (communityId: string): Promise<Property> => {
  return await axios
    .get<Property>(`${apiUrl}/communities/${communityId}`)
    .then((response) => response.data);
};

const getPlanById = async (planId: string): Promise<Property> => {
  return await axios.get<Property>(`${apiUrl}/plans/${planId}`).then((response) => response.data);
};

const GetProfileProperties = new Map<
  PhotoRequestType,
  (companyId?: string, params?: ProfilePropertyQueryFilter) => Promise<PropertyResponse[]>
>([
  [PhotoRequestType.Community, getCommunities],
  [PhotoRequestType.Plan, getPlans]
]);

const GetProperty = new Map<PhotoRequestType, (id: string) => Promise<Property>>([
  [PhotoRequestType.Community, getCommunityById],
  [PhotoRequestType.Residential, getListingById],
  [PhotoRequestType.Plan, getPlanById]
]);

export { getListings, GetProfileProperties, GetProperty };
