import React from 'react';
import { Typography, CardProps, Card, CardActionArea, CardContent, Grid } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';

import { PhotoRequestType } from '@homesusa/photo-request';

export function SelectRequestType({
  onSelect
}: {
  onSelect: (value: PhotoRequestType) => void;
}): JSX.Element {
  const [selected, setSelected] = React.useState<PhotoRequestType>();

  const onSelectType = (type: PhotoRequestType): void => {
    setSelected(type);
    onSelect(type);
  };

  const getCardProps = (requestType: PhotoRequestType): CardProps => {
    const className = 'CardButton';
    return {
      className: className + (requestType === selected ? ` ${className}-selected` : '')
    };
  };

  return (
    <div>
      <Typography variant="subtitle1" align="center" sx={{ my: 2 }}>
        Select Photo Request Type
      </Typography>
      <Grid container columnSpacing={2} justifyContent="space-between">
        <Grid item xs={4}>
          <Card {...getCardProps(PhotoRequestType.Residential)}>
            <CardActionArea onClick={(): void => onSelectType(PhotoRequestType.Residential)}>
              <CardContent>
                <HomeOutlinedIcon fontSize="large" />
                <Typography variant="h3">Residential</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card {...getCardProps(PhotoRequestType.Community)}>
            <CardActionArea onClick={(): void => onSelectType(PhotoRequestType.Community)}>
              <CardContent>
                <HomeWorkOutlinedIcon fontSize="large" />
                <Typography variant="h3">Community</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card {...getCardProps(PhotoRequestType.Plan)}>
            <CardActionArea onClick={(): void => onSelectType(PhotoRequestType.Plan)}>
              <CardContent>
                <OtherHousesIcon fontSize="large" />
                <Typography variant="h3">Plan</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
