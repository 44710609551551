import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormContext } from '@homesusa/form';
import { AppContext } from '@homesusa/layout';
import { useFormErrors } from '@homesusa/photo-request';
import { PhotoRequestService } from 'core/services';

export const useCreatePhotoRequest = (): (() => Promise<void>) => {
  const { addAlert } = React.useContext(AppContext);
  const { formState } = React.useContext(FormContext);
  const navigate = useNavigate();
  const hasError = useFormErrors();

  const onSave = async (): Promise<void> => {
    if (!formState || hasError()) {
      return Promise.resolve();
    }

    return PhotoRequestService.createPhotoRequest(formState).then((id: string) => {
      addAlert({
        message: 'The request was successfully created',
        variant: 'success'
      });
      navigate(`/photo-requests/${id}`);
    });
  };

  return onSave;
};
