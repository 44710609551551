import React from 'react';
import { Box, Stack } from '@mui/material';

import { Input } from '@homesusa/form';
import { SubmitButton } from '@homesusa/layout';
import { PhotoRequestType } from '@homesusa/photo-request';
import { GetProfileProperties } from '../services/quicklister.service';
import { ProfilePropertyQueryFilter } from '../interfaces/query-filters';
import { PropertyResponse } from '../interfaces';

export function SearchProfileProperties({
  companyId,
  onSearch,
  type
}: {
  companyId: string;
  type: PhotoRequestType;
  onSearch: (value: PropertyResponse[]) => void;
}): JSX.Element {
  const [queryFilter, setQueryFilter] = React.useState<ProfilePropertyQueryFilter>();
  const setQueryFilterValue =
    (name: string) =>
    (value: unknown): void => {
      setQueryFilter((prev) => ({
        ...prev,
        [name]: value
      }));
    };

  const searchProperties = async (): Promise<void> => {
    const getProperties = GetProfileProperties.get(type);
    if (getProperties) {
      const response = await getProperties(companyId, queryFilter);
      onSearch(response);
    }
  };

  return (
    <Stack spacing={2}>
      <Input label="Name" onChange={setQueryFilterValue('name')} />
      <Box sx={{ textAlign: 'center' }}>
        <SubmitButton onClick={searchProperties}>Search</SubmitButton>
      </Box>
    </Stack>
  );
}
