import React from 'react';

import { PhotoRequest } from '@homesusa/photo-request';
import { PhotoRequestResponse } from 'core/interfaces';
import { PhotoRequestService } from 'core/services';

export const useGetPhotoRequest = (
  photoRequestId: string
): {
  photoRequest?: PhotoRequest;
  getPhotoRequest: (refresh?: boolean) => Promise<PhotoRequestResponse>;
  completedCount?: number;
} => {
  const [photoRequest, setPhotoRequest] = React.useState<PhotoRequest>();
  const [completedCount, setCompletedCount] = React.useState<number>();
  const prevPhotoRequestId = React.useRef<string | undefined>();
  React.useEffect(() => {
    prevPhotoRequestId.current = photoRequest?.id;
  });

  const getPhotoRequest = React.useCallback(
    async (refresh?: boolean) => {
      if (!!refresh || prevPhotoRequestId.current != photoRequestId) {
        prevPhotoRequestId.current = photoRequestId;
        setPhotoRequest(undefined);
      }
      const { completedPhotoRequestCount, ...photoRequest } =
        await PhotoRequestService.getPhotoRequest(photoRequestId);
      setPhotoRequest(photoRequest);
      setCompletedCount(completedPhotoRequestCount ?? 0);
      return photoRequest;
    },
    [photoRequestId]
  );

  return { photoRequest, getPhotoRequest, completedCount };
};
