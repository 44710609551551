import React from 'react';
import { Tooltip, IconButtonProps, IconButton as MuiIconButton } from '@mui/material';

export function IconButton({ title, children, ...props }: IconButtonProps): JSX.Element {
  return (
    <Tooltip title={title ?? ''}>
      <MuiIconButton aria-label={title} {...props}>
        {children}
      </MuiIconButton>
    </Tooltip>
  );
}
