import axios from 'axios';
import { StatusCode } from 'status-code-enum';

import { GridResponse } from '@homesusa/grid-table';
import { PhotoRequest, PhotoRequestType } from '@homesusa/photo-request';
import { PhotoRequestGrid, PhotoRequestResponse } from 'core/interfaces';

const apiUrl = process.env.REACT_APP_API_URL + 'api/photo-requests';

const getActivePhotoRequests = async (params: {
  status?: string[];
  type?: string;
  pageSize?: number;
  startIndex?: number;
  searchBy?: string;
  sortBy?: string;
  isForDownloading?: boolean;
}): Promise<GridResponse<PhotoRequestGrid>> => {
  if (params?.type === PhotoRequestType.None) {
    params.type = undefined;
  }
  return axios
    .get<GridResponse<PhotoRequestGrid>>(apiUrl, {
      params
    })
    .then((response) => response.data);
};

const getPhotoRequest = async (id: string): Promise<PhotoRequestResponse> => {
  const response = await axios.get<PhotoRequestResponse>(`${apiUrl}/${id}`);
  return response.data;
};

const createPhotoRequest = async (photoRequest: PhotoRequest): Promise<string> => {
  const response = await axios.post(apiUrl, photoRequest);
  return response.data;
};

const createVirtualStaging = async (photoRequestId: string, images: string[]): Promise<string> => {
  const response = await axios.post(`${apiUrl}/${photoRequestId}/virtual-staging`, { images });
  return response.data;
};

const submitPhotoRequest = async (photoRequest: PhotoRequest, message: string): Promise<void> => {
  await axios.patch(`${apiUrl}/${photoRequest.id}/submit`, { message });
};

const approvePhotoRequest = async (photoRequestId: string): Promise<void> => {
  await axios.patch(`${apiUrl}/${photoRequestId}/approve`, {});
};

const unlockPhotoRequest = async (photoRequestId: string): Promise<void> => {
  await axios.patch(`${apiUrl}/${photoRequestId}/unlock`, {});
};

const notifyPhotoRequestEdited = async (photoRequestId: string, message: string): Promise<void> => {
  await axios.patch(`${apiUrl}/${photoRequestId}/notify-edits`, { message });
};

const cancelPhotoRequest = async (
  photoRequest: PhotoRequest,
  cancelledReason: string
): Promise<boolean> => {
  const response = await axios.put(`${apiUrl}/${photoRequest.id}/cancel`, { cancelledReason });
  return response.status == StatusCode.SuccessOK;
};

const sendMessageToPhotographer = async (
  photoRequest: PhotoRequest,
  message: string
): Promise<boolean> => {
  const response = await axios.put(`${apiUrl}/${photoRequest.id}/message`, {
    message
  });
  return response.status == StatusCode.SuccessOK;
};

const reportError = async (photoRequest: PhotoRequest, message: string): Promise<boolean> => {
  const response = await axios.put(`${apiUrl}/${photoRequest.id}/error`, {
    message
  });
  return response.status == StatusCode.SuccessOK;
};

const updatePhotoRequest = async (photoRequest: PhotoRequest): Promise<boolean> => {
  const response = await axios.put(`${apiUrl}/${photoRequest.id}`, photoRequest);
  return response.status == StatusCode.SuccessOK;
};

const deletePhotoRequest = async (id: string): Promise<boolean> => {
  const response = await axios.delete(`${apiUrl}/${id}`);
  return response.status == StatusCode.SuccessOK;
};

const assignPhotographer = async ({
  photoRequestId,
  userId
}: {
  photoRequestId: string;
  userId: string;
}): Promise<boolean> => {
  const response = await axios.put(
    `${apiUrl}/${photoRequestId}/photographer`,
    {
      userId
    },
    { withCredentials: true }
  );
  return response.status == StatusCode.SuccessOK;
};

const photosRejectRequest = async (
  photoRequest: PhotoRequest,
  rejectedReason: string
): Promise<boolean> => {
  const response = await axios.put(`${apiUrl}/${photoRequest.id}/reject-photos`, {
    rejectedReason
  });
  return response.status == StatusCode.SuccessOK;
};

export {
  getActivePhotoRequests,
  getPhotoRequest,
  createPhotoRequest,
  updatePhotoRequest,
  deletePhotoRequest,
  cancelPhotoRequest,
  assignPhotographer,
  sendMessageToPhotographer,
  reportError,
  submitPhotoRequest,
  approvePhotoRequest,
  createVirtualStaging,
  photosRejectRequest,
  unlockPhotoRequest,
  notifyPhotoRequestEdited
};
