import { Company } from '@homesusa/photo-request';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL + 'api/companies';
export const getCompanyInfo = async (id: string): Promise<Company> => {
  const response = await axios.get(`${apiUrl}/${id}`);
  const data: Company = response.data as Company;
  data.id = id;
  return data;
};
