import React from 'react';
import {
  Typography,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SxProps
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export const AccordionStyled = styled(Accordion)(() => ({
  background: '#EDEDED',
  '& .MuiButtonBase-root': {
    padding: 0,
    background: 'white',
    '& div': { padding: '0 16px' }
  }
}));

export const AccordionBox = ({
  children,
  title,
  sx
}: {
  children: React.ReactNode;
  title?: string | JSX.Element;
  sx?: SxProps;
}): JSX.Element => {
  return (
    <AccordionStyled disableGutters sx={sx} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {typeof title !== 'string' ? (
          title
        ) : (
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionStyled>
  );
};
