import React from 'react';

import { AppContext } from '@homesusa/layout';
import { PhotoRequest } from '@homesusa/photo-request';
import { PhotoRequestContext } from '../contexts';

export const useTripChargeReason = (
  successMessage: string,
  serviceMethod: (photoRequest: PhotoRequest, reason: string) => Promise<boolean>
): {
  onSubmit: () => Promise<boolean>;
  hasError: boolean;
  onChangeReason: (reason: string) => void;
} => {
  const { photoRequest, getPhotoRequest } = React.useContext(PhotoRequestContext);
  const [hasError, setHasError] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const { addAlert } = React.useContext(AppContext);

  const onChangeReason = (value: string): void => {
    if (value != null && value !== '') {
      setHasError(false);
    }
    setReason(value);
  };

  const onSubmit = async (): Promise<boolean> => {
    if (reason == null || reason === '') {
      setHasError(true);
      return false;
    }

    setHasError(false);
    await serviceMethod(photoRequest, reason);
    addAlert({
      message: successMessage,
      variant: 'success'
    });
    getPhotoRequest();
    return true;
  };

  return {
    onSubmit,
    hasError,
    onChangeReason
  };
};
