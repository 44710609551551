import React from 'react';
import { Box, LinearProgress } from '@mui/material';

import { PhotoRequestFormProvider, PropertyTypeLabel, Company } from '@homesusa/photo-request';
import { AuthCompanyContext, useUserProfile } from '@homesusa/auth';
import { FormContext } from '@homesusa/form';
import { PersistentRightModal, RoleUser, Stepper as HusaStepper, useRefresh } from '@homesusa/core';
import { getCompanyInfo } from 'core/services/company.service';
import { PhotoFormOtherContextProps } from '../../contexts';
import { PhotoForm } from './photo-form.component';
import { SelectStepContent } from './select-step-content.component';
import { CentralizedMessage } from '../centralized-message';
import { PhotographyServiceDisabledMessage } from '../photography-service-disabled-message';

export function PhotoRequestCreateSteps({
  setOpenModal,
  openModal
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}): JSX.Element {
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const user = useUserProfile();

  const [companyDetails, setCompanyDetails] = React.useState<Company>();
  const getCompanyDetails = async (companyId: string): Promise<Company> => {
    if (companyDetails && companyId == companyDetails.id) {
      return companyDetails;
    }
    const company = await getCompanyInfo(companyId);
    setCompanyDetails(company);
    return company;
  };
  const [hasCompletedPhotoRequest, setHasCompletedPhotoRequest] = React.useState(false);
  const [isCompleteHome, setIsCompleteHome] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const [hideForm, resfreshForm] = useRefresh();

  React.useLayoutEffect(() => {
    if (currentCompany?.id) {
      getCompanyDetails(currentCompany.id);
    }
  }, [currentCompany?.id]);

  if (!!companyDetails && !companyDetails?.photographyServiceInfo?.photographyServicesEnabled) {
    return (
      <PhotographyServiceDisabledMessage
        showModal={openModal}
        onCloseModal={(): void => setOpenModal(false)}
      />
    );
  }

  if (
    user &&
    user.role != RoleUser.MLSAdministrator &&
    companyDetails &&
    companyDetails.photoRequestInfo.isCentralized &&
    companyDetails.photoRequestInfo.photoManagerId != user.id
  ) {
    return (
      <CentralizedMessage
        showModal={openModal}
        onCloseModal={(): void => setOpenModal(false)}
        photoManagerName={companyDetails?.photoRequestInfo.photoManagerName}
        photoManagerEmail={companyDetails?.photoRequestInfo.photoManagerEmail}
      />
    );
  }

  if (hideForm) {
    return <LinearProgress />;
  }

  return (
    <PhotoRequestFormProvider<PhotoFormOtherContextProps>
      initialData={Object.assign({})}
      otherContextProps={{
        getCompanyDetails,
        fullPage: false,
        hasCompletedPhotoRequest,
        setHasCompletedPhotoRequest,
        company: companyDetails,
        isCompleteHome,
        setIsCompleteHome
      }}
    >
      <FormContext.Consumer>
        {({ formState: { property } }): JSX.Element => (
          <PersistentRightModal
            title={
              !property?.type
                ? 'Create Photo Request'
                : `Create Photo Request - ${PropertyTypeLabel.get(property.type)}`
            }
            open={openModal}
            onChange={(value): void => {
              if (!value) {
                setActiveStep(0);
                resfreshForm();
              }
              setOpenModal(value);
            }}
          >
            <HusaStepper
              steps={['Type', 'Submit']}
              activeStep={activeStep}
              setActiveStep={(value): void => {
                setActiveStep(value);
                if (value === 0) {
                  resfreshForm();
                }
              }}
            >
              <Box px={10}>
                <HusaStepper.Content index={0} selected={activeStep}>
                  <Box mt={3}>
                    <SelectStepContent handleNext={handleNext} />
                  </Box>
                </HusaStepper.Content>
              </Box>
              <HusaStepper.Content index={1} selected={activeStep}>
                <Box mt={3} />
                <PhotoForm />
              </HusaStepper.Content>
            </HusaStepper>
          </PersistentRightModal>
        )}
      </FormContext.Consumer>
    </PhotoRequestFormProvider>
  );
}
