import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { MarketCode, MarketCodeLabel } from '@homesusa/core';
import { AuthCompanyContext } from '@homesusa/auth';

export function SelectMarket(props: TextFieldProps): JSX.Element {
  const { currentMarket } = React.useContext(AuthCompanyContext);

  const label = 'Market';

  return (
    <TextField
      {...props}
      label={props.label ?? label}
      select
      fullWidth
      defaultValue={currentMarket}
      SelectProps={{
        native: true
      }}
      variant="outlined"
    >
      <option aria-label="None" value="" />
      {Object.values(MarketCode).map((key) => (
        <option key={key} value={key}>
          {MarketCodeLabel.get(key as MarketCode)}
        </option>
      ))}
    </TextField>
  );
}
