import React, { Fragment } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, Box } from '@mui/material';

import { isValidGuid } from '@homesusa/core';
import { SubmitButton } from '@homesusa/layout';
import { PhotoRequestContext } from '../../contexts';

export function Modal({
  title,
  actionButton,
  children,
  dialogProps
}: {
  dialogProps: DialogProps;
  title: string;
  actionButton: { text: string; method: () => Promise<void> };
  children: React.ReactNode;
}): JSX.Element {
  const { photoRequest } = React.useContext(PhotoRequestContext);
  if (photoRequest && !isValidGuid(photoRequest.id)) {
    return <Fragment></Fragment>;
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box mt={1} minWidth={500}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        <SubmitButton onClick={actionButton.method}>{actionButton.text}</SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
