import React from 'react';

import { FormContext, getOptionValueFromRule } from '@homesusa/form';
import { PhotoRequestType } from '@homesusa/photo-request';
import { PhotoFormContextProps, PhotoRequestContext } from '../contexts';

export const usePhotoRequestTitle = (): string => {
  const {
    photoRequest: { modelAddress, type, property, companyName }
  } = React.useContext(PhotoRequestContext);
  const { formRules } = React.useContext<PhotoFormContextProps>(FormContext);

  const title = React.useMemo(() => {
    const city = getOptionValueFromRule(formRules['property.city'], property.city);

    if (type == PhotoRequestType.Community) {
      return `${companyName} - ${modelAddress}, ${city}`;
    }

    if (type == PhotoRequestType.Plan) {
      return `${companyName} - ${property.planName}`;
    }

    const planName = !!property.planName ? `Plan Name: ${property.planName}` : null;
    const address = `${companyName} - ${property.streetNum} ${property.streetName}, ${city}${
      property.unitNumber ? '. Unit Number: ' + property.unitNumber : ''
    }`;
    return planName ? `${address} | ${planName}` : address;
  }, [modelAddress, type, companyName, property]);

  return title;
};
