import React, { Fragment } from 'react';
import { Grid, Box, Stack } from '@mui/material';

import { Input } from '@homesusa/form';
import { SubmitButton } from '@homesusa/layout';
import { getListings } from '../services/quicklister.service';
import { ResidentialPropertyQueryFilter } from '../interfaces/query-filters';
import { PropertyResponse } from '../interfaces';

export function SearchResidentialProperties({
  companyId,
  onSearch
}: {
  companyId: string;
  onSearch: (value: PropertyResponse[]) => void;
}): JSX.Element {
  const [queryFilter, setQueryFilter] = React.useState<ResidentialPropertyQueryFilter>();
  const setQueryFilterValue =
    (name: string) =>
    (value: unknown): void => {
      setQueryFilter((prev) => ({
        ...prev,
        [name]: value
      }));
    };

  const searchProperties = async (): Promise<void> => {
    const response = await getListings(companyId, queryFilter);
    onSearch(response);
  };

  if (companyId == null) {
    return <Fragment />;
  }

  return (
    <Stack spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Input label="MLS Number" onChange={setQueryFilterValue('mlsNumber')} />
        </Grid>
        <Grid item xs={4}>
          <Input label="Street Number" onChange={setQueryFilterValue('streetNumber')} />
        </Grid>
        <Grid item xs={4}>
          <Input label="Street Name" onChange={setQueryFilterValue('streetName')} />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'center' }}>
        <SubmitButton onClick={searchProperties}>Search</SubmitButton>
      </Box>
    </Stack>
  );
}
