import { MarketHeader } from '@homesusa/auth';
import { Company } from '@homesusa/photo-request';
import axios from 'axios';

export const getUserCompanies = async (
  userId: string,
  filterByMarket = true
): Promise<Company[]> => {
  const config = !filterByMarket
    ? {
        headers: { [MarketHeader]: null }
      }
    : undefined;
  const apiUrl =
    process.env.REACT_APP_API_URL + `api/users/${userId}/companies?photoServiceEnabledOnly=true`;
  const response = await axios.get(`${apiUrl}`, config);
  const data: Company[] = response.data as Company[];
  return data;
};
