import { GridResponse } from '@homesusa/grid-table';
import axios from 'axios';
import {
  Photographer,
  PhotographerPayrollResponse,
  PhotographerReportRequest,
  PhotograpyBillingReportFilter,
  PhotographersBillingReport,
  PhotographerServicesRequest
} from 'core/interfaces';

const apiUrl = process.env.REACT_APP_API_URL + 'api/photographers';

const getPhotographers = async (params?: {
  skip?: number;
  take?: number;
  sortBy?: string;
  searchBy?: string;
  isForDownloading?: boolean;
}): Promise<{ data: Photographer[]; total: number }> => {
  const response = await axios.get<GridResponse<Photographer>>(apiUrl, { params });
  return response.data;
};

const getPhotographer = async (id: string): Promise<Photographer> => {
  const response = await axios.get<Photographer>(`${apiUrl}/${id}`);
  return response.data;
};

const getPhothographerServices = async (id: string): Promise<PhotographerServicesRequest[]> => {
  const response = await axios.get(`${apiUrl}/${id}/photography-services`);
  return response.data;
};

const createPhotographerService = async (
  id: string,
  service: PhotographerServicesRequest
): Promise<boolean> => {
  const response = await axios.post(`${apiUrl}/${id}/photography-services`, service);
  return response.data;
};

const updatePhotographerService = async (
  id: string,
  service: PhotographerServicesRequest
): Promise<boolean> => {
  const response = await axios.put(`${apiUrl}/${id}/photography-services`, service, {
    params: { market: service.market }
  });
  return response.data;
};

const getPhotographersPayroll = async (
  body: PhotographerReportRequest
): Promise<PhotographerPayrollResponse> => {
  const response = await axios.get<PhotographerPayrollResponse>(`${apiUrl}/payroll`, {
    params: {
      PhotographerIds: [body.photographerId],
      StartDate: body.startDate,
      EndDate: body.endDate,
      MarketCode: body.marketCode,
      BuildersIds: [body.buildersIds],
      PhotoRequestTypes: body.photoRequestType
    }
  });
  return response.data;
};

export {
  getPhotographers,
  getPhotographer,
  getPhothographerServices,
  createPhotographerService,
  updatePhotographerService,
  getPhotographersPayroll
};

export const getBillingReport = async (
  params: PhotograpyBillingReportFilter
): Promise<PhotographersBillingReport> => {
  const response = await axios.get<PhotographersBillingReport>(`${apiUrl}/billing-report`, {
    params
  });
  return response.data;
};
