import React, { Fragment } from 'react';

import { AuthCompanyContext, useUserServices } from '@homesusa/auth';
import { FormContext } from '@homesusa/form';
import { getInitialPhotoInformation } from '@homesusa/photo-request';
import { SelectCompany as CoreSelectCompany } from 'core/components';
import { PhotoFormContextProps } from '../../contexts';

export function SelectCompany(): JSX.Element {
  const {
    otherProps: { getCompanyDetails },
    formState: { type, property },
    formDispatch
  } = React.useContext<PhotoFormContextProps>(FormContext);
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const { getUserDetails } = useUserServices();

  const getAndSetCompany = async (companyId?: string): Promise<void> => {
    const company = !!companyId ? await getCompanyDetails(companyId) : undefined;

    const user = await getUserDetails();
    const photoInfo = !!company ? getInitialPhotoInformation({ user, company }) : {};

    if (formDispatch) {
      formDispatch({
        type: 'OverrideState',
        payload: Object.assign({
          ...photoInfo,
          type,
          property: {
            type: property?.type
          }
        })
      });
    }
  };

  React.useLayoutEffect(() => {
    if (currentCompany?.id) {
      getAndSetCompany(currentCompany.id);
    }
  }, [currentCompany?.id]);

  if (currentCompany) {
    return <Fragment></Fragment>;
  }

  return (
    <CoreSelectCompany
      onSelect={(company): void => {
        getAndSetCompany(company?.id);
      }}
    />
  );
}
