import React from 'react';
import { FormControl, TextField, Checkbox, Autocomplete, Chip } from '@mui/material';
import { PhotoRequestType, PhotoRequestTypeLabel } from '@homesusa/photo-request';

type FieldData = { value: string; label: string };

export function MultiSelectPhotoRequestType(props: {
  defaultValue?: string[];
  onChange: (value: string[]) => void;
  label: string;
}): JSX.Element {
  const [itemsSelected, setItemsSelected] = React.useState<FieldData[]>([]);

  const options = React.useMemo(() => {
    const withoutNone = Object.values(PhotoRequestType).filter(
      (value) => value !== PhotoRequestType.None
    );
    return withoutNone.map((value) => ({
      value,
      label: PhotoRequestTypeLabel.get(value) ?? value
    }));
  }, []);

  const onClose = (value: FieldData[]): void => {
    if (props.onChange) {
      props.onChange(value.map((option) => option.value));
    }
  };

  const getSeleted = (options: FieldData[]): FieldData[] => {
    return options.filter((option) => itemsSelected?.find((item) => item.value === option.value));
  };

  return (
    <FormControl
      component="fieldset"
      sx={{
        width: '100%',
        '& .MuiAutocomplete-popper': {
          minWidth: 200,
          maxWidth: 350
        }
      }}
    >
      <Autocomplete
        multiple
        disableCloseOnSelect
        getOptionLabel={(option): string => option.label}
        options={options}
        value={getSeleted(options)}
        onChange={(_, newValue: FieldData[]): void => {
          setItemsSelected(newValue);
        }}
        limitTags={2}
        onClose={(): void => onClose(itemsSelected)}
        disablePortal
        renderOption={(optionProps, option, { selected }): React.ReactNode => (
          <li {...optionProps}>
            <Checkbox checked={selected} color="primary" size="small" />
            {option.label}
          </li>
        )}
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            size="small"
            sx={{ maxWidth: 500 }}
          />
        )}
        renderTags={(value, getTagProps): JSX.Element[] =>
          value.map((option, index): JSX.Element => {
            const { key, onDelete, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                label={option.label}
                size="small"
                key={key}
                onDelete={(event): void => {
                  onDelete(event);
                  onClose([...itemsSelected].filter((x) => x.value != option.value));
                }}
                {...tagProps}
              />
            );
          })
        }
      />
    </FormControl>
  );
}
